/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#main_welcome {
  text-align: center;
  margin: auto;
  height: 100vh;
  width: 100vh;
}

.App {
  text-align: center;
  margin: auto;
  width: 100%;
}

#EOP {
  font-size: 4rem;
}

.page-main {
  width: 100% !important;
  margin: auto;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

.page-btn {
  font-size: 1.25em;
  position: absolute;
  bottom: 0.5em;
  right: 0.5em;
}

.header {
  text-align: left;
  background-color: #adadad;

  color: white;
  height: 80px;
}

.header-text {
  padding-left: 2em;
  padding-top: 0.65em;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

#nav-bar {
  width: 100%;
  height: 100vh;
  position: sticky;
  top: 0;
  /* background-color: #231f20; */
  background-image: linear-gradient(180deg, #818181 0%, #231f20 70%);
}

#root {
  width: auto;
  overflow-x: auto;
}

.cred-box {
  width: 95%;
  align-content: center;
  margin: auto;
  margin-top: 2.5em;
  height: 20em;
  background-color: lightgrey;
  border: 2px solid #cc0000;
}

.card-text {
  padding-top: 3em;
  color: black;
}

.input-field {
  width: 85%;
  margin: 0.5em;
}

#login-header {
  margin-top: 0;
  margin-bottom: 1.5em;
}

.icon {
  margin-right: 1em;
}

.login-landing {
  width: 95%;
  align-content: center;
  margin: auto;
  margin-top: 2.5em;
  height: 20em;
  background-color: lightgrey;
  border: 2px solid #cc0000;
}

.login-landing h2 {
  margin-top: 2em;
  margin-bottom: 1em;
}

.pending-order-btn {
  position: absolute;
  bottom: 0.5em;
}

.approver-order-btn {
  position: absolute;
  bottom: 0.5em;
  right: 11em;
}

.nav-btn {
  text-align: left;
  color: #fff;
}

.nav-btn:hover {
  color: #aaa;
}

.homePage-info {
  color: #cc0000;
  font-style: italic;
}

.page-header-info {
  color: #cc0000;
}

.pending-orders-landing {
  width: 95%;
  align-content: center;
  margin: auto;
  margin-top: 2.5em;
  max-height: Auto;
  border: 2px solid #cc0000;
}

.pending-grey {
  background-color: lightgrey;
}

.heavy-text {
  font-weight: bold;
}

#pending-orders-table table {
  margin: auto;
}

.padding {
  padding: 0.25em;
  padding-left: 2em !important;
}

.padding-top-bottom {
  padding: 0.25em;
}

#asl-dd {
  position: absolute;
  left: 0.7%;
  top: 0.06%;
}

#store-dd {
  max-height: 50vh;
  overflow: auto;
}

.table-header {
  background-color: #cc0000;
  color: white;
}

.faq-question {
  font-weight: bold;
}

.faq-answer {
  font-size: 20px;
  font-weight: 400;
}

.home-heading {
  /* background-color: grey; */
  /* color: white; */
  padding: 0.75em 2em 0.5em 2em;
  font-weight: bolder;
  font-size: 30px;
  width: 100%;
}
.home-big-heading {
  /* background-color: grey; */
  /* color: white; */
  /* padding: 0.75em 7em 0.5em 2em; */
  font-weight: bolder;
  font-size: 40px;
  width: 100%;
}

.home-heading-icon {
  background-color: #cc0000;
  color: white;
  padding: 0.5em 3em 0.5em 3em;
}

.row-centered {
  text-align: center;
}

.page-heading {
  padding-top: "0.5em";
  padding-bottom: 0.5em;
  color: #cc0000;
  font-weight: bolder;
  width: 100%;
  font-size: 50px;
}

.home-text {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  padding: 0 2em 0 2em;
}

.grey-border {
  border-style: solid none solid none;
  border-color: #dedede;
}

.white-border {
  border-style: solid none solid none;
  border-color: white;
  /* margin: auto;
	width: 40%; */
}
.red-text {
  color: #cc0000;
}

.dark-red-button {
  background-color: #cc0000;
  color: white;
}

.totals-Row {
  background-color: #ff9999;
}

.red-background {
  background-color: #cc0000;
}

.red-text {
  color: #cc0000;
}

.esop-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  width: 80%;
  /* margin: 0 auto; */
}

.clipped {
  padding: 10px;
  width: 600px;
  color: #ddd;
  background: #ddd;
  background-color: #cc0000;
  text-align: left;
  -webkit-clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
  clip-path: polygon(0 0, 100% 0%, 95% 100%, 0% 100%);
}

.clipped > * {
  max-width: 580px;
}

.banner-grey {
  background-color: #adadad;
  padding: 4px;
}

.pannelbar-wrapper {
  padding-top: 1em;
}

.k-link {
  padding: 8px, 16px;
}

.banner-background {
  background-image: url("./components/pictures/ESOP_Headers.png");
  background-repeat: no-repeat;
  height: 60px;
  width: 1000px;
}

.banner-background-cs {
  background-image: url("./components/pictures/CommonStock_Headers.png");
  background-repeat: no-repeat;
  height: 60px;
  width: 1000px;
}

.table-boarder-left {
  border-left: 2px solid #cc0000 !important;
}

.table-boarder-right {
  border-right: 2px solid #cc0000 !important;
}
